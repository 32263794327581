<template>
  <div>
    <b-card>
      <h1 class="mb-0">Today's Analytics: {{ todaysDate }}</h1>
    </b-card>
    <div class="cards-container">
      <b-card class="progress-container" title="">
        <b-spinner
          class="spinner"
          v-if="accProgressLoading"
          variant="success"
          label="Text Centered"
        />

        <div class="demo-vertical-spacing">
          <div
            v-for="acc in allAccounts"
            :key="acc.id"
            class="progress-wrapper"
          >
            <span class="progress-span">
              <b-card-text :class="'mb-0 text-' + acc.variant">
                {{ acc.account_name }}: {{ acc.percentage }}%
              </b-card-text>
              <b-progress
                v-model="acc.percentage"
                max="100"
                :class="'progress-bar-' + acc.variant"
              />
            </span>
            <span class="buttons-span">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="'outline-' + acc.variant"
                to="/notifications-programming"
              >
                Send
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="'outline-' + acc.variant"
                to="/notifications"
              >
                Manage
              </b-button>
            </span>
          </div>
        </div>
      </b-card>
      <b-card class="circle-progress-container">
        <b-spinner
          class="spinner"
          v-if="totalPercentageLoading"
          variant="success"
          label="Text Centered"
        />
        <vue-apex-charts
          type="radialBar"
          height="245"
          :options="goalOverviewRadialBar.chartOptions"
          :series="goalOverviewRadialBar.series"
          class="apex-circle"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard, BBadge, BProgress, BCardText, BSpinner } from "bootstrap-vue";
import AppEchartLine from "@core/components/charts/echart/AppEchartLine.vue";
import VueApexCharts from "vue-apexcharts";
import axios from "@axios";
import { $themeColors } from "@themeConfig";
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  components: {
    BCard,
    AppEchartLine,
    BBadge,
    VueApexCharts,
    BProgress,
    BCardText,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      p: 15,
      goalOverviewRadialBar: {
        // series: [83],
        series: [],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ["#51e5a8"],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: "77%",
              },
              track: {
                background: "#ebe9f1",
                strokeWidth: "50%",
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: "#5e5873",
                  fontSize: "2.86rem",
                  fontWeight: "600",
                },
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      },
      /** FOR PROGRESS **/
      value: 0,
      value1: 25,
      value2: 50,
      value3: 75,
      value4: 100,
      /** END OF PROGRESS **/
      allAccounts: [],
      accountsProgress: [],
      toBeSent: null,
      sent: null,
      toBeSentArray: [],
      percentageValue: [],
      sentArray: [],
      totalToBeSent: null,
      totalSent: null,
      totalPercentage: null,
      todaysDate: null,
      accProgressLoading: null,
      totalPercentageLoading: null,
      accountsOneSignalState: {},
    };
  },
  mounted() {
    this.checkAllAccountsOnesignalState();
  },
  methods: {
    findTotalPercentage() {
      this.totalPercentageLoading = true;
      var currentDate = new Date();
      this.todaysDate = moment(currentDate).format("DD/MM/YYYY");
      // **** finding totalToBeSent **** //
      axios
        .post("/getTotalToBeSent", {
          notif_day: currentDate.getDay(),
        })
        .then((response) => {
          this.totalToBeSent = response.data.count;
          axios
            .get("/getTotalSent")
            .then(({ data }) => {
              this.totalSent = data.count;
            })
            .then((_) => {
              const percentage = (
                (this.totalSent / this.totalToBeSent) *
                100
              ).toFixed(1);

              if (this.totalToBeSent >= this.totalSent) {
                this.goalOverviewRadialBar.series = [
                  isNaN(percentage) ? 0 : percentage,
                ];
              } else {
                this.goalOverviewRadialBar.series = [(100).toFixed(1)];
              }
              this.totalPercentageLoading = false;
            })
            .catch(console.error);
        })
        .catch(console.error);
      //**** finding totalSent ****//
    },
    /** FOR PROGRESS **/
    async checkAllAccountsOnesignalState() {
      axios
        .get("/get-all-onesignal-accounts")
        .then(({ data }) => {
          this.accountsOneSignalState = data.accounts_state;
        })
        .catch(console.error);
    },
    supposedToBeSent() {
      this.accProgressLoading = true;
      var currentDate = new Date();
      var toBeSent = null;
      var sent = null;
      var account = {};
      this.$store
        .dispatch("appsManager/getAllAccounts")
        .then((response) => {
          response.data["accounts"].forEach((el) => {
            // this.allAccounts.push({id:el.id,name:el.account_name});
            axios
              .post("/getNotifsByAccount", {
                account_id: el.id,
                notif_day: currentDate.getDay(),
              })
              .then(({ data: { count } }) => {
                toBeSent = count;
                this.toBeSentArray.push({ id: el.id, toBeSent: toBeSent });
                axios
                  .post("/getSentNotifsByAccount", {
                    account_id: el.id,
                  })
                  .then(({ data }) => {
                    sent = data.count;
                    this.sentArray.push({ id: el.id, sent: sent });

                    this.accProgressLoading = false;
                    account["id"] = el.id;
                    account["account_name"] = el.account_name;
                    account["supposedToBeSent"] = this.toBeSentArray.find(
                      (element) => element.id == el.id
                    ).toBeSent;
                    account["sent"] = this.sentArray.find(
                      (element) => element.id == el.id
                    ).sent;

                    if (account["supposedToBeSent"] != 0) {
                      if (account["supposedToBeSent"] >= account["sent"]) {
                        account["percentage"] = (
                          (account["sent"] / account["supposedToBeSent"]) *
                          100
                        ).toFixed(1);
                      } else {
                        account["percentage"] = 100;
                      }
                    } else {
                      account["percentage"] = 0;
                    }
                    if (account["percentage"] == 100) {
                      account["variant"] = "success";
                    } else {
                      account["variant"] = "danger";
                    }
                    if (this.accountsOneSignalState[account["id"]]) {
                      this.allAccounts.push(account);
                    }
                    account = {};
                  })
                  .catch(console.error);
              })
              .catch(console.error);
          });
        })
        .catch(console.error);
    },
  },
  async created() {
    this.supposedToBeSent();
    this.findTotalPercentage();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
.progress-container {
  width: 60% !important;
  display: inline-block;
  margin: 5px;
  position: relative;
}
.circle-progress-container {
  width: 40% !important;
  display: inline-block;
  margin: 5px;
  position: relative;
}
.circle-progress-container .card-body {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}
.cards-container {
  display: flex;
  flex-direction: row;
}
.progress-wrapper {
  display: flex;
  flex-direction: row;
  // width: 100% !important;
}
.progress-span {
  width: calc(60%);
  margin: 0 5px;
}
.buttons-span {
  width: calc(40%);
  margin: 0 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.buttons-span a {
  margin: 0 3px;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>